'use client'

import '@/app/style/globals.scss'
import type { Metadata } from 'next'
import * as React from 'react'
import Script from 'next/script'
import Link from 'next/link'
import CookieConsent from '@/app/components/CookieConsent'
import Navbar from '@/app/components/Navbar'
import Footer from '@/app/components/Footer'

export const generateMetadata = async (): Promise<Metadata> => ({
  title: '404 Not Found | PicTrix.ai',
  robots: 'noindex, nofollow',
})

export default function NotFound(): React.ReactElement {
  return (
    <main>
      <Script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5261304523430352"
        crossOrigin="anonymous"
      />
      <main className="relative mx-auto min-h-screen w-full bg-neutral-50 dark:bg-neutral-800">
        <Navbar />
        <main id="content" className="mb-24 w-full pb-6">
          <div className="grid">
            <div className="col-start-1 row-start-1 fill-neutral-100 object-cover dark:fill-neutral-900">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100">
                <path d="M1000 0H0v52C62.5 28 125 4 250 4c250 0 250 96 500 96 125 0 187.5-24 250-48V0Z" />
              </svg>
            </div>
            <div className="col-start-1 row-start-1">
              <div className="mx-auto mb-24 mt-12 flex flex-col gap-8">
                <div className="dark:shadow-shadow-dark border-border dark:border-border-dark mx-auto flex w-full max-w-xs flex-col gap-8 rounded-xl border p-8 shadow-xl sm:max-w-sm">
                  <h1 className="text-center text-7xl font-bold">404</h1>
                  <div className="overflow-hidden text-center text-sm">
                    <p className="mb-2 text-3xl">Page not found</p>
                  </div>
                  <div className="overflow-hidden text-center text-sm">
                    <p className="text-secondary-text dark:text-secondary-text-dark mb-2 text-xl">
                      Oops! You&apos;ve found a page that doesn&apos;t exist. Congrats?
                    </p>
                  </div>
                  <div className="mx-auto">
                    <Link href="/" className="button button-primary mt-4">
                      Let&apos;s bring you back home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
        <CookieConsent />
      </main>
    </main>
  )
}
